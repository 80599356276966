import React, { useState } from "react"
import { CSSTransition } from 'react-transition-group';
import Flickity from 'react-flickity-component'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const flickityOptions = {
  initialIndex: 6,
  autoPlay: true,
  cellAlign: 'center',
  contain: true,
  wrapAround: true,
  prevNextButtons: false,
  pageDots: false,
  imagesLoaded: true
}

export default function Spotify() {
  const data = useStaticQuery(
    graphql`
      {
        allSpotifyTopTrack(filter: {time_range: {}}, sort: {fields: order}) {
          edges {
            node {
              id
              time_range
              album {
                id
                artists {
                  name
                }
                name
                images {
                  height
                  url
                  width
                }
                localImages {
                  childImageSharp {
                    fluid(maxWidth: 400, maxHeight: 250) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                external_urls {
                  spotify
                }
              }
            }
          }
        }
      }
    `
  )

  let tempSelect,
      tempOption;

  function matchSelectWidth(select) {
    tempSelect = document.querySelector('#temp-width-select');
    tempOption = document.querySelector('#temp-width-option');

    let currentSelect = select.querySelector('option:checked').innerHTML;

    tempOption.innerHTML = currentSelect;
    select.style.width = tempSelect.offsetWidth + 'px';
  }

  // Mix em up for fun
  const shuffledTracks = data.allSpotifyTopTrack.edges.sort(() => Math.random() - 0.5);

  // Set the time_ranges
  const timeRanges = ['short_term', 'medium_term', 'long_term'];

  // Loop through each time range and build array of matched albums
  timeRanges.forEach(timeRange => {
    timeRanges[timeRange] = shuffledTracks.filter(track => (track.node.time_range.includes(timeRange)));

    let albums = timeRanges[timeRange].map(track => track.node.album);

    const albumIds = albums.map(o => o.id);
    timeRanges[timeRange] = albums.filter(({id}, index) => !albumIds.includes(id, index + 1));
  });

  // State
  const [currentTimeRange, setCurrentTimeRange] = useState('short_term');

  return (
    <div className="spotify">
      <div className="timerange-selection section-title">
        <div>What I've been listening to</div> <div>
          <select
            name="timeframe"
            id="timeframe"
            onChange={(event) => {
                setCurrentTimeRange(event.target.value);
                matchSelectWidth(event.target);
              }
            }
          >
            <option value="short_term">recently</option>
            <option value="medium_term">for awhile</option>
            <option value="long_term">for a long time</option>
          </select>
          <select id="temp-width-select">
            <option id="temp-width-option">recently</option>
          </select> <span className="arrow">↓</span>
        </div>
      </div>

      {timeRanges.map(timeRange => {
        return (
          <CSSTransition
            in={currentTimeRange === timeRange}
            timeout={1000}
            classNames="albumTransitionGroup"
            key={timeRange}
          >
            <div
              className="jukebox-container"
              hidden={currentTimeRange !== timeRange}
              aria-hidden={currentTimeRange !== timeRange}
            >
              <Flickity
                className={'jukebox'}
                options={flickityOptions}
                reloadOnUpdate={true}
              >
                {timeRanges[currentTimeRange].map(album => {
                  return (
                      <div key={album.id} className="album col-1-2 col-sm-1-3 col-md-1-5">
                        <a
                          href={album.external_urls.spotify}
                          target="_blank"
                          rel="noreferrer noopener"
                          className="treated-image"
                        >
                            {/* Waiting on https://github.com/graysonhicks/gatsby-plugin-remote-images/pull/62 */}
{/*                          <Img
                            fluid={album.localImages[0].fluid}
                          />*/}
                          <img src={album.images[0].url} alt="{album.name}" />
                        </a>
                        <h4>
                          <a href={album.external_urls.spotify} target="_blank" rel="noreferrer noopener">
                            {album.name} by {album.artists[0].name}
                          </a>
                        </h4>
                      </div>
                  )
                })}
              </Flickity>
            </div>
          </CSSTransition>
        )
      })}
    </div>
  )
}
