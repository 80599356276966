import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default function Codepen() {
  const data = useStaticQuery(
    graphql`
      {
        craftApi {
          entry {
            ... on CraftAPI_web_web_Entry {
              id
              codepens {
                ... on CraftAPI_codepens_pen_BlockType {
                  id
                  penEmbed
                  penTitle
                  penUrl
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <div className="codepens grid spaced">
      <div className="col-md-1-2">
        {data.craftApi.entry.codepens.map(pen => {
          return (
            <div key={pen.id} className="pen">
              <h4><a href={pen.penUrl} target="_blank" rel="noreferrer noopener">{pen.penTitle}</a></h4>
              <div dangerouslySetInnerHTML={{ __html: pen.penEmbed }}></div>
            </div>
          )
        })}
      </div>
    </div>
  )
}