import React from "react"
import Layout from "../components/layout"
import Codepen from "../sections/codepen"
import Spotify from "../components/spotify"


export default function Home({ data }) {
  return (
    <Layout title="home">
      <div id="work" className="section reveal">
        <h2 className="section-title">What I've been working on recently:</h2>
        <p>Recent Work Here</p>
      </div>

      <div id="about" className="section reveal">
        <h2 className="section-title">Who I am:</h2>
        <p>Lorem ipsum dolor sit amet, consectetur, adipisicing elit. Eligendi tempora harum fugit provident, possimus repellendus, nihil earum, ipsum non excepturi dolorem a repellat perspiciatis. Facilis possimus velit ut explicabo, fugit!</p>
      </div>

      <div id="experiments" className="section">
        <h2 className="section-title reveal">What I've been experimenting on recently:</h2>

        <div>
          <Codepen />
        </div>
      </div>

      <div id="music" className="section reveal">
        <Spotify />
      </div>
    </Layout>
  );
}
